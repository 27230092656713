import React, { useEffect, useState, useRef } from "react";
import { graphql } from "gatsby";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../layout";
import Seo from "../components/seo";
import {
  AdvantagesContainer,
  AdvantagesSubTitle,
  AdvantagesTitle,
  AdvantagesTop,
  AdvantagesWrapper,
  MotivationContainer,
  MotivationContent,
  MotivationTitle,
  SLifeContainer,
  SLifeContent,
  SLifeHeadingSection,
  SLifeWrapper,
  STeamContainer,
  STeamContent,
  STeamName,
  STeamPosition,
  STeamWrapper,
  STooltip,
  WhoWeAreBackground,
  SWhoWeAreContainer,
  WhoWeAreBackgroundInner,
  LifeWrapperBackground,
} from "../styles/about-us";
import { HOST_IMAGE_URL } from "../constants";
import Container from "../components/container";
import useDragList from "../hooks/use-drag-list";
import LifePeopleIcon from "../assets/icons/LifePeopleIcon";
import Advantages from "../components/advatages/Advantages";
import AdvantagesSlider from "../components/advatages/AdvantagesSlider";
import ArrowHeading from "../components/arrowHeading";
import theme from "../theme";
import {
  SliderAdaptiveContainer,
  SliderBackground,
} from "../components/slider/styles";
import LogoBackground from "../assets/icons/LogoBackground";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";

function AboutUs({
  data: {
    strapi: { aboutUs, teams, advantages, malevichLives },
  },
}) {
  const isMobile = useBreakpointValue(["smallMobile", "mobile"]);
  const teamRef = useDragList();
  const lifeRef = useDragList();

  const containerRef = useRef(null);
  const teamTooltipRef = useRef(null);
  const lifeTooltipRef = useRef(null);

  const [teamStyles, setTeamStyles] = useState({});

  const handleMouseMove = (ref) => (e) => {
    const { pageX, pageY } = e;
    if (ref.current) {
      if (!parseInt(ref.current.style.opacity)) {
        ref.current.style.opacity = 1;
      }

      ref.current.style.top = pageY + 20 + "px";
      ref.current.style.left = pageX + 20 + "px";
    }
  };

  const handleMouseLeave = (ref) => () => {
    ref.current.style.opacity = 0;
  };

  useEffect(() => {
    if (containerRef.current) {
      setTeamStyles({
        marginLeft: `${containerRef.current.offsetLeft * -1}px`,
        paddingLeft: `${containerRef.current.offsetLeft}px`,
        width: `calc(100% + ${containerRef.current.offsetLeft * 2}px)`,
      });
    }
  }, []);

  return (
    <Layout>
      <Seo
        title={aboutUs?.data?.attributes?.seo?.metaTitle}
        description={aboutUs?.data?.attributes?.seo?.metaDescription}
        image={aboutUs?.data?.attributes?.seo?.metaImage?.data?.attributes?.url}
      />
      <Container direction="column" ref={containerRef}>
        <WhoWeAreBackground>
          <WhoWeAreBackgroundInner>
            <LogoBackground />
          </WhoWeAreBackgroundInner>
          <SWhoWeAreContainer>
            <Typography
              variant="h1"
              dangerouslySetInnerHTML={{
                __html: aboutUs.data.attributes.title,
              }}
            />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: aboutUs.data.attributes.description,
              }}
            />
          </SWhoWeAreContainer>

          {/* TEAM SECTION */}
          <STeamWrapper>
            <ArrowHeading>{aboutUs.data.attributes.teamTitle}</ArrowHeading>
            <STeamContainer
              onMouseMove={handleMouseMove(teamTooltipRef)}
              onMouseLeave={handleMouseLeave(teamTooltipRef)}
              ref={teamRef}
              sx={teamStyles}
            >
              {teams?.data?.map((item) => {
                return (
                  <STeamContent key={item.id}>
                    <img
                      src={`${
                        HOST_IMAGE_URL +
                        item?.attributes?.photo?.data?.attributes?.url
                      }`}
                      alt={
                        item?.attributes?.photo?.data?.attributes
                          ?.alternativeText
                      }
                      title={item?.attributes?.photo?.data?.attributes?.caption}
                    />
                    <STeamName variant="h2" component="p">
                      {item.attributes.name}
                    </STeamName>
                    <STeamPosition>{item.attributes.profession}</STeamPosition>
                  </STeamContent>
                );
              })}
            </STeamContainer>
            <STooltip
              ref={teamTooltipRef}
              sx={{ [theme.breakpoints.down("lg")]: { marginTop: "-30px" } }}
            >
              Drag
            </STooltip>
          </STeamWrapper>
          {/* END OF TEAM SECTION */}
        </WhoWeAreBackground>

        {/* ADVANTAGES SECTION */}
        <AdvantagesWrapper>
          <AdvantagesTop>
            <AdvantagesTitle
              variant="h2"
              component="div"
              dangerouslySetInnerHTML={{
                __html: aboutUs.data.attributes.advantages.title,
              }}
            />
            <AdvantagesSubTitle
              dangerouslySetInnerHTML={{
                __html: aboutUs.data.attributes.advantages.description,
              }}
            />
          </AdvantagesTop>
          <AdvantagesContainer>
            {isMobile ? (
              <SliderAdaptiveContainer>
                <AdvantagesSlider advantages={advantages} />
                <SliderBackground />
              </SliderAdaptiveContainer>
            ) : (
              <Advantages advantages={advantages} />
            )}
          </AdvantagesContainer>
        </AdvantagesWrapper>
        {/* END OF ADVANTAGES SECTION */}

        {/* MOTIVATION SECTION */}
        <MotivationContainer>
          <MotivationTitle
            variant="h2"
            component="div"
            dangerouslySetInnerHTML={{
              __html: aboutUs.data.attributes.motivation.title,
            }}
          />
          <MotivationContent
            dangerouslySetInnerHTML={{
              __html: aboutUs.data.attributes.motivation.description,
            }}
          />
        </MotivationContainer>
        {/* END OF MOTIVATION SECTION */}

        {/* LIFE AT MALEVICH */}
        <SLifeWrapper>
          <LifeWrapperBackground>
            <LogoBackground />
          </LifeWrapperBackground>
          <SLifeHeadingSection>
            <Typography
              variant="h2"
              dangerouslySetInnerHTML={{
                __html: aboutUs.data.attributes.lifeAtMalevichTitle,
              }}
            />
            <LifePeopleIcon />
          </SLifeHeadingSection>
          <Box>
            <SLifeContainer
              onMouseMove={handleMouseMove(lifeTooltipRef)}
              onMouseLeave={handleMouseLeave(lifeTooltipRef)}
              ref={lifeRef}
              sx={teamStyles}
            >
              {malevichLives.data.map((item) => {
                return (
                  <SLifeContent key={item.id}>
                    <img
                      src={`${
                        HOST_IMAGE_URL +
                        item.attributes.photo.data.attributes.url
                      }`}
                      alt={
                        item.attributes.photo.data.attributes.alternativeText
                      }
                      title={item.attributes.photo.data.attributes.caption}
                    />
                  </SLifeContent>
                );
              })}
            </SLifeContainer>
          </Box>
          <STooltip ref={lifeTooltipRef}>Drag</STooltip>
        </SLifeWrapper>
        {/* END OF LIFE AT MALEVICH */}
      </Container>
    </Layout>
  );
}

export default AboutUs;

export const query = graphql`
  query {
    strapi {
      aboutUs {
        data {
          attributes {
            seo {
              ...seo
            }
            title
            description
            teamTitle
            advantages {
              title
              description
            }
            motivation {
              title
              description
            }
            lifeAtMalevichTitle

            localizations {
              data {
                attributes {
                  title
                  description
                  teamTitle
                  advantages {
                    title
                    description
                  }
                  motivation {
                    title
                    description
                  }
                  lifeAtMalevichTitle
                }
              }
            }
          }
        }
      }

      teams(pagination: { limit: 100 }, sort: ["sort:desc"]) {
        data {
          id
          attributes {
            photo {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            name
            profession
          }
        }
      }

      advantages {
        data {
          id
          attributes {
            title
            description
          }
        }
      }

      malevichLives(pagination: { limit: 100 }) {
        data {
          id
          attributes {
            photo {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
          }
        }
      }
    }
  }
`;
