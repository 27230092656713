import { styled } from "@mui/system";
import palette from "../theme/palette";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import theme from "../theme";

export const WhoWeAreBackground = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("lg")]: {
    height: "unset",
  },
});

export const WhoWeAreBackgroundInner = styled(Box)({
  position: "absolute",
  top: "-90px",
  left: 0,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    top: "-65px",
  },

  [theme.breakpoints.down("lg")]: {
    top: "-45px",
  },

  [theme.breakpoints.down("md")]: {
    top: "-22px",
  },
});

export const SWhoWeAreContainer = styled(Box)({
  maxWidth: 680,
  width: "100%",
  paddingTop: 120,

  "& h1": {
    marginBottom: 44,
  },

  [theme.breakpoints.down("lg")]: {
    paddingTop: 44,

    "& h1": {
      marginBottom: 16,
    },
  },
});

// TEAM SECTION
export const HeadingSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: { xs: "space-between", sm: "flex-start" },
});

export const STeamWrapper = styled(Box)({
  margin: "120px 0px 75px",

  [theme.breakpoints.down("lg")]: {
    margin: "80px 0 0",
  },
});

export const STeamContainer = styled(Box)({
  display: "flex",
  marginTop: 44,
  position: "relative",
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  transition: "all 0.2s",
  willChange: "transform",
  userSelect: "none",
  cursor: "move",
  scrollbarColor: "transparent transparent",
  scrollbarWidth: "thin",

  "&::-webkit-scrollbar": {
    width: "3px",
    height: "3px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "& > .active": {
    cursor: "grabbing",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: 26,
  },
});

export const STeamContent = styled(Box)({
  width: "280px",
  marginRight: 20,
  display: "inline-block",
  float: "left",
  flexShrink: 0,

  "&:hover": {
    h2: {
      color: palette.purpleBlue,
    },
    img: {
      filter: "none",
      pointerEvents: "none",
    },
  },

  "@media (max-width: 768px)": {
    img: {
      filter: "none",
    },
  },

  "@media (max-width: 899.99px)": {
    width: "215px",
  },

  "&:nth-of-type(2n+1)": {
    img: {
      height: "390px",

      [theme.breakpoints.down("lg")]: {
        height: "300px",
      },
    },
  },

  "&:nth-of-type(2n+2)": {
    img: {
      height: "341px",

      [theme.breakpoints.down("lg")]: {
        height: "262px",
      },
    },
  },

  img: {
    width: "280px",
    objectFit: "cover",
    objectPosition: "center",
    marginBottom: 16,
    filter: "grayscale(100%)",

    [theme.breakpoints.down("lg")]: {
      width: "215px",
    },
  },
});

export const STeamName = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  marginBottom: 8,
  textTransform: "uppercase",

  "@media (max-width: 899.99px)": {
    fontSize: 18,
    lineHeight: "26px",
  },
});

export const STeamPosition = styled(Box)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "26px",
  letterSpacing: "0.15px",
  whiteSpace: "break-spaces",
});

export const STooltip = styled("span")({
  display: "none",
  position: "absolute",
  padding: ".5em 1em",
  backgroundColor: palette.mediumBlack,
  borderRadius: 100,
  color: palette.secondary,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
});
// END OF TEAM SECTION

// ADVANTAGES SECTION
export const AdvantagesWrapper = styled(Box)({});

export const AdvantagesTop = styled(Box)({
  maxWidth: "910px",
  width: "100%",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "uppercase",
});

export const AdvantagesTitle = styled(Typography)({
  color: palette.primary,
  fontWeight: 600,
  lineHeight: "56px",
  textTransform: "none",

  "& strong": {
    color: palette.purpleBlue,
    fontWeight: 600,
    lineHeight: "56px",
    textTransform: "none",
  },
});

export const AdvantagesSubTitle = styled(Box)({
  marginTop: "26px",
});

export const AdvantagesContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

export const AdvantagesItem = styled(Box)({
  maxWidth: "500px",
  width: "100%",
  marginTop: "80px",
  padding: 26,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",

  "& .advantagesItemTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    paddingBottom: "26px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "uppercase",
    borderBottom: `1px solid ${palette.primary}`,
  },

  "&:hover": {
    color: palette.secondary,
    background: palette.primary,

    "& .advantagesItemTitle": {
      borderBottom: `1px solid ${palette.secondary}`,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "420px",
    marginTop: "26px",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
    color: palette.secondary,
    background: palette.primary,

    "& .advantagesItemTitle": {
      borderBottom: `1px solid ${palette.secondary}`,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  "@media (max-width: 375px)": {
    padding: 16,
    fontSize: "14px",
    lineHeight: "20px",

    "& .advantagesItemTitle": {
      marginBottom: "16px",
      paddingBottom: "16px",
    },
  },
});
// END OF ADVANTAGES SECTION

// MOTIVATION SECTION
export const MotivationContainer = styled(Box)({
  marginTop: 92,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
});

export const MotivationTitle = styled(Typography)({
  fontWeight: 600,
  lineHeight: "62px",
  letterSpacing: 0,

  "& strong": {
    color: palette.purpleBlue,
    fontWeight: 700,
    lineHeight: "62px",
    letterSpacing: 0,
  },

  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
    lineHeight: "32px",

    "& strong": {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
});

export const MotivationContent = styled(Box)({
  maxWidth: 600,
  width: "100%",
  marginTop: 36,

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },
});
// END OF MOTIVATION SECTION

export const SResultContent = styled(Box)({
  marginTop: 6,

  [theme.breakpoints.down("lg")]: {
    marginTop: 0,
  },

  "& ul": {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },

  "& li": {
    maxWidth: 456,
    width: "100%",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "0.15px",
    marginTop: 26,
    marginBottom: 0,
    listStyle: "none",
    listStylePosition: "inside",

    "&:last-child": {
      marginLeft: 15,

      [theme.breakpoints.down("lg")]: {
        marginLeft: 0,
      },
    },
  },
});

export const SObjectivesContainer = styled(Box)({
  margin: "170px 0px",

  [theme.breakpoints.down("lg")]: {
    margin: 0,
  },
});

export const SObjectivesContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },

  "& img": {
    maxWidth: 617,
    width: "100%",
    marginRight: 20,
    marginBottom: 0,
    height: "100%",

    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      marginTop: 26,
    },
  },

  "& h2": {
    maxWidth: 456,

    "@media (max-width: 960px)": {
      fontSize: 34,
      lineHeight: "40px",
    },
  },

  "& p": {
    maxWidth: 456,
    width: "100%",
    fontSize: 20,
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "0.15px",
    marginTop: 32,
    marginBottom: 0,
  },
});

export const SLifeWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  height: "1260px",
  marginBottom: "-582px",

  [theme.breakpoints.down("lg")]: {
    height: "unset",
    marginBottom: "0px",
    background: "none",
    paddingTop: "80px",
  },
});

export const LifeWrapperBackground = styled(Box)({
  position: "absolute",
  top: "-90px",
  left: 0,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});

export const SLifeHeadingSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  h2: {
    marginRight: 15,
    fontSize: "82px",
    fontWeight: 500,
    lineHeight: "90px",

    strong: {
      color: palette.purpleBlue,
      fontSize: "82px",
      fontWeight: 700,
      lineHeight: "90px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    svg: {
      maxWidth: "150px",
      maxHeight: "150px",
    },

    h2: {
      fontSize: "34px",
      lineHeight: "42px",

      strong: {
        fontSize: "34px",
        lineHeight: "42px",
      },
    },
  },
});

export const SLifeContainer = styled(Box)({
  display: "flex",
  marginTop: "-75px",

  "@media (max-width: 899.99px)": {
    marginTop: "-30px",
  },

  position: "relative",
  width: "100%",
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  transition: "all 0.2s",
  willChange: "transform",
  userSelect: "none",
  cursor: "move",
  scrollbarColor: "transparent transparent",
  scrollbarWidth: "thin",

  "&::-webkit-scrollbar": {
    width: "3px",
    height: "3px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "& > .active": {
    cursor: "grabbing",
  },
});

export const SLifeContent = styled(Box)({
  marginRight: "20px",
  // display: "inline-block",
  // float: "left",

  img: {
    minWidth: "480px",
    width: "480px",
    minHeight: "340px",
    height: "340px",
    objectFit: "cover",
    objectPosition: "center",
    marginBottom: "0px",
    pointerEvents: "none",

    [theme.breakpoints.down("lg")]: {
      minWidth: "314px",
      width: "314px",
      minHeight: "213px",
      height: "213px",
    },
  },
});
