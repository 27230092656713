import React from "react";
import { AdvantagesItem } from "../../styles/about-us";
import Cube from "../../assets/icons/Cube";

const Advantages = ({ advantages }) => {
  return (
    <>
      {advantages.data.map((advantage) => {
        return (
            <AdvantagesItem key={advantage.id}>
              <div className="advantagesItemTitle">
                {advantage.attributes.title}
                <Cube />
              </div>
              <div>{advantage.attributes.description}</div>
            </AdvantagesItem>
        );
      })}
    </>
  );
};

export default Advantages;
