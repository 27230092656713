import React from "react";
import { Slider } from "../slider";
import { AdvantagesItem } from "../../styles/about-us";
import Cube from "../../assets/icons/Cube";

const AdvantagesSlider = ({ advantages }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
        {advantages.data.map((advantage) => {
            return (
                <AdvantagesItem key={advantage.id}>
                    <div className="advantagesItemTitle">
                        {advantage.attributes.title}
                        <Cube />
                    </div>
                    <div>{advantage.attributes.description}</div>
                </AdvantagesItem>
            );
        })}
    </Slider>
  );
};

export default AdvantagesSlider;
